/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

 import React from "react"
 import PropTypes from "prop-types"
 import { useStaticQuery, graphql } from "gatsby"
//  import { FirebaseContext, useAuth } from './Firebase';
 import '../App.css'
 import "./layout.css"
 import { Helmet } from "react-helmet";
 import Header from "./Header/"
 import Footer from "./footer"
 import styled from 'styled-components'
 import useSiteMetadata from '../hooks/use-site-metadata';

 const DivAttack = styled.div`
 margin: 0 auto;
 padding: 0 1.0875rem 1.45rem;
 background: #fff;
 padding-top: 100px;
 
 a{
  color: #212529;
 }
 `
 


 const Layout = ({ children, location }) => {
  //  const { user, firebase, loading } = useAuth();
  const { url } = useSiteMetadata();
   const data = useStaticQuery(graphql`
     query SiteTitleQuery {
       site {
         siteMetadata {
           title
         }
       }
     }
   `)
 
   return (
 <>
            <Helmet htmlAttributes={{lang: 'en'}}>
        
          <link rel="canonical" href={`${url}${location.pathname}`} />
        </Helmet>
       <Header siteTitle={data.site.siteMetadata.title} />
       <DivAttack
         
       >
         <main style={{ marhginTop: 10 }}>{children}</main>
       </DivAttack>
       <Footer />
        {/* </PageWrapper> */}
     </>
     
   )
 }
 
 Layout.propTypes = {
   children: PropTypes.node.isRequired,
 }
 
 export default Layout
 