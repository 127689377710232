export const menuItems = [
  
 
  {
    name: "Compare",
    label: "Compare",
    items: [
      { name: "https://www.startupmag.co.uk/compare/accounting-software/", isExternal: true, label: "Accounting Software" },
      { name: "https://www.startupmag.co.uk/compare/amazon-software/", isExternal: true, label: "Amazon Software" },
      { name: "https://www.startupmag.co.uk/compare/crm-software/", isExternal: true, label: "CRM Software" },
      { name: "https://www.startupmag.co.uk/compare/payment-providers/", isExternal: true, label: "Payment Providers" },
      { name: "https://www.startupmag.co.uk/compare/seo-software/", isExternal: true, label: "SEO Software" },
      { name: "https://www.startupmag.co.uk/compare/website-builders/", isExternal: true, label: "Website Builders" },
      { name: "https://www.startupmag.co.uk/compare/wordpress-hosting/", isExternal: true, label: "Wordpress Hosting" },
    ],
  },
  
  {
    name: "Podcast",
    label: "Podcast",
    items: [
      { name: "https://www.startupmag.co.uk/compare/podcast-hosting/", isExternal: true, label: "Podcast Hosting" },
      { name: "https://www.startupmag.co.uk/compare/podcast-mics/", isExternal: true, label: "Podcast Mics" },
   
    ],
  },
  {
    name: "How To Guides",
    label: "How To Guides",
    items: [
 
      {
        name: "Business ideas",
        label: "Business ideas",
        items: [
          { name: "https://www.startupmag.co.uk/business-ideas/", isExternal: true, label: "UK business ideas" },
          { name: "https://www.startupmag.co.uk/dark-kitchen/", isExternal: true, label: "Dark kitchen" },
          { name: "https://www.startupmag.co.uk/start-a-shipping-business/", isExternal: true, label: "Shipping business" },
          { name: "https://www.startupmag.co.uk/paid-newsletter-business/", isExternal: true, label: "Paid newsletters" },
          { name: "https://www.startupmag.co.uk/10-rules-for-new-ideas/", isExternal: true, label: "Get new ideas" },
        ],
      },

      {
        name: "Choose software",
        label: "Choose software",
        items: [
          { name: "https://www.startupmag.co.uk/business-software/", isExternal: true, label: "Business software" },
          { name: "https://www.startupmag.co.uk/cloud-based-accounting-software/", isExternal: true, label: "Better accounting" },
          { name: "https://www.startupmag.co.uk/crm-software-choosing-right-will-boost-your-sales/", isExternal: true, label: "Boost sales" },
          { name: "https://www.startupmag.co.uk/data-visualisation-software-that-will-make-your-data-actually-mean-something/", isExternal: true, label: "Data visualisation" },
          { name: "https://www.startupmag.co.uk/start-taking-payments-on-your-website/", isExternal: true, label: "Take payments" },
          { name: "https://www.startupmag.co.uk/website-builders-make-your-website-yourself/", isExternal: true, label: "Build a website" },
         
        ],
      },


      {
        name: "Find an investor",
        label: "Find an investor",
        items: [
          { name: "https://www.startupmag.co.uk/pre-seed-vcs-in-uk-london/", isExternal: true, label: "Pre-seed VCs" },
          { name: "https://www.startupmag.co.uk/startup-accelerators-in-london/", isExternal: true, label: "Startup Accelerators" },
          { name: "https://www.startupmag.co.uk/unicorn-alert/", isExternal: true, label: "Unicorn Alert" },
          
        ],
      },
      {
              name: "Find an office",
              label: "Find an office",
              items: [
                { name: "https://www.startupmag.co.uk/coworking-spaces-in-brixton-london/", isExternal: true, label: "London, Brixton" },
                { name: "https://www.startupmag.co.uk/co-working-spaces-in-waterloo-london/", isExternal: true, label: "London, Waterloo" },
                { name: "https://www.startupmag.co.uk/co-working-spaces-in-battersea-london/", isExternal: true, label: "London, Battersea" },
                { name: "https://www.startupmag.co.uk/co-working-spaces-in-victoria-london/", isExternal: true, label: "London, Victoria" },
                { name: "https://www.startupmag.co.uk/co-working-spaces-in-london/", isExternal: true, label: "Co-working London" },
              ],
            },
            {
              name: "Make a website",
              label: "Make a website",
              items: [
                { name: "https://www.startupmag.co.uk/make-a-website/", isExternal: true, label: "Make a website" },
                { name: "https://www.startupmag.co.uk/learn-to-code/", isExternal: true, label: "Learn to code" },
                { name: "https://www.startupmag.co.uk/add-sound-audio-to-your-website/", isExternal: true, label: "Add sound" },
                { name: "https://www.startupmag.co.uk/coding-bootcamps-in-london/", isExternal: true, label: "Coding bootcamps" },
                { name: "https://www.startupmag.co.uk/wordpress-stop-buying-themes-now/", isExternal: true, label: "Wordpress themes" },
                ],
                },
            {
              name: "Marketing",
              label: "Marketing",
              items: [
                { name: "https://www.startupmag.co.uk/how-to-optimize-your-amazon-product-titles/", isExternal: true, label: "Amazon Titles" },
                { name: "https://www.startupmag.co.uk/google-ads/", isExternal: true, label: "Google Ads" },
                { name: "https://www.startupmag.co.uk/automatic-bidding-google-is-just-a-dude-looking-for-answers/", isExternal: true, label: "Google Ads Bidding" },
                { name: "https://www.startupmag.co.uk/enhanced-cpc-bidding-the-secret-scaling-weapon/", isExternal: true, label: "Google Ads CPC" },
                { name: "https://www.startupmag.co.uk/google-ads-tactics-to-double-your-conversion-rates/", isExternal: true, label: "Google Ads Tactics" },
                { name: "https://www.startupmag.co.uk/add-google-analytics-to-your-website-in-8-easy-steps/", isExternal: true, label: "Google Analytics" },
                { name: "https://www.startupmag.co.uk/landing-page/", isExternal: true, label: "Landing Pages" },
                { name: "https://www.startupmag.co.uk/34-rules-to-crush-your-new-twitter-account/", isExternal: true, label: "Crushing Twitter" },
                ],
                },
          
            {
              name: "Recruitment",
              label: "Recruitment",
              items: [
                { name: "https://www.startupmag.co.uk/job-offer/", isExternal: true, label: "Job offer" },
                { name: "https://www.startupmag.co.uk/sales-development-representative-sdr/", isExternal: true, label: "SDR" },
                { name: "https://www.startupmag.co.uk/where-to-post-your-job-ads-online/", isExternal: true, label: "Online job ads" },
                ],
                },

                {
                  name: "Tactics",
                  label: "Tactics",
                  items: [
                    { name: "https://www.startupmag.co.uk/change-business-name/", isExternal: true, label: "Change business name" },
                    { name: "https://www.startupmag.co.uk/dont-let-mvping-mess-up-your-launch/", isExternal: true, label: "Make an MVP?" },
                    { name: "https://www.startupmag.co.uk/online-parcel-delivery-who-should-i-pick/", isExternal: true, label: "Online parcel delivery" },
                    ],
                    },
    ],
      },
      { name: "", label: "Investment Data" },

     
  
      




     
  

 
  // {
  //   name: "pages",
  //   label: "Pages",
  //   items: [
  //     { name: "about", label: "About" },
  //     {
  //       name: "career",
  //       label: "Career",
  //       items: [
  //         { name: "career", label: "Career List" },
  //         { name: "career-details", label: "Career Details" },
  //       ],
  //     },
  //     {
  //       name: "case-study",
  //       label: "Case Study",
  //       items: [
  //         { name: "case-study", label: "Case Study" },
  //         { name: "case-study-details", label: "Case Study Details" },
  //       ],
  //     },
  //     {
  //       name: "blog",
  //       label: "Blog",
  //       items: [
  //         { name: "blog-regular", label: "Blog Regular" },
  //         { name: "blog-left-sidebar", label: "Blog Left Sidebar" },
  //         { name: "blog-right-sidebar", label: "Blog Right Sidebar" },
  //         { name: "blog-details", label: "Blog Details" },
  //       ],
  //     },
  //     {
  //       name: "contact",
  //       label: "Contact",
  //       items: [
  //         { name: "contact-1", label: "Contact 01" },
  //         { name: "contact-2", label: "Contact 02" },
  //         { name: "contact-3", label: "Contact 03" },
  //       ],
  //     },
  //     {
  //       name: "account",
  //       label: "Account",
  //       items: [
  //         { name: "signin", label: "Signin" },
  //         { name: "signin-bg", label: "Signin BG" },
  //         { name: "signup", label: "Signup" },
  //         { name: "signup-bg", label: "Signup BG" },
  //         { name: "reset-pass", label: "Reset Password" },
  //         { name: "reset-pass-bg", label: "Reset Password BG" },
  //       ],
  //     },
  //     {
  //       name: "pricing",
  //       label: "Pricing",
  //       items: [
  //         { name: "pricing-1", label: "Pricing 01" },
  //         { name: "pricing-2", label: "Pricing 02" },
  //       ],
  //     },
  //     {
  //       name: "utility",
  //       label: "Utility",
  //       items: [
  //         { name: "coming-soon", label: "Coming Soon" },
  //         { name: "coming-soon-2", label: "Coming Soon 02" },
  //         { name: "404", label: "404 Page" },
  //         { name: "thank-you", label: "Thank You" },
  //         { name: "terms-conditions", label: "Terms & Conditions" },
  //       ],
  //     },
  //     {
  //       name: "essential",
  //       label: "Essential",
  //       items: [
  //         { name: "faq", label: "FAQ" },
  //         { name: "reviews", label: "Reviews" },
  //       ],
  //     },
  //   ],
  // },
  
];
