import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { Title, Box } from "./Core";
import { Link } from "gatsby"

const TitleStyled = styled(Title)`
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.5px;
  margin-bottom: 22px;
`;

const UlStyled = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  li {
    line-height: 0.8;
    a {
      color: white !important;
      &:hover {
        text-decoration: none;
        color: ${({ theme, color }) => '#5454d4'} !important;
      }
    }
  }
`;

const CopyRightArea = styled.div`
  border-top: ${({ dark, theme }) =>
    dark ? `1px solid #2f2f31 ` : `1px solid #413e65`};

  padding: 15px 0;
  p {
    color: ${({ dark, theme }) =>
      dark ? '#ffffff' : '#19191b'};
    font-size: 13px;
    font-weight: 300;
    letter-spacing: -0.41px;
    line-height: 38px;
    margin-bottom: 0;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: inline-flex;
    a {
      color: ${({ dark, theme }) =>
        dark ? '#ffffff' : '#19191b'} !important;
      font-size: 16px;
      transition: 0.4s;
      padding: 0 3px;
      margin: 0 2.5px;
      &:visited {
        text-decoration: none;
      }
      &:hover {
        text-decoration: none;
        color: ${({ theme, color }) => '#5454d4'} !important;
      }
    }
  }
`;

const Footer = ({ isDark = true }) => {
  return (
    <>
      {/* <!-- Footer section --> */}
      <Box bg={'#19191b'}>
        <Container>
          <Box
            css={`
              padding: 80px 0 60px;
            `}
          >
            <Row className="justify-content-center">

              <Col sm="12" className="mt-5 mt-lg-0">
                <Row>
                 
                  <Col xs="6" lg="3">
                    <div className="mb-5 mb-lg-4">
                      <TitleStyled
                        variant="card"
                        color={'#fff'}
                        style={{fontSize: 18, fontWeight: 700, lineHeight: '28px', letterSpacing: '-1.5px', fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif'}}
                      >
                        Compare
                      </TitleStyled>
                      <UlStyled color={isDark ? "lightShade" : "darkShade"} style={{fontWeight: 300}}>
                        <li>
                          <Link to={"https://www.startupmag.co.uk/compare/accounting-software/"} style={{fontSize: 14}}>
                            Accounting software
                          </Link>
                        </li>
                        <li>
                        <Link to={"https://www.startupmag.co.uk/compare/amazon-software/"} style={{fontSize: 14}}>
                            Amazon software
                          </Link>
                        </li>
                        <li>
                        <Link to={"https://www.startupmag.co.uk/compare/crm-software/"} style={{fontSize: 14}}>
                            CRM software
                          </Link>
                        </li>
                        <li>
                        <Link to={"https://www.startupmag.co.uk/compare/payment-providers/"} style={{fontSize: 14}}>
                            Payment providers
                          </Link>
                        </li>
                        <li>
                        <Link to={"https://www.startupmag.co.uk/compare/seo-software/"} style={{fontSize: 14}}>
                            SEO software
                          </Link>
                        </li>
                        <li>
                        <Link to={"https://www.startupmag.co.uk/compare/website-builders/"} style={{fontSize: 14}}>
                            Website builders
                          </Link>
                        </li>
                      
                        <li>
                        <Link to={"https://www.startupmag.co.uk/compare/wordpress-hosting/"} style={{fontSize: 14}}>
                            Wordpress hosting
                          </Link>
                        </li>
                      
                      
                      </UlStyled>
                    </div>
                  </Col>

                  <Col xs="6" lg="3">
                    <div className="mb-5 mb-lg-4">
                      <TitleStyled
                        variant="card"
                        color={'#fff'}
                        style={{fontSize: 18, fontWeight: 700, lineHeight: '28px', letterSpacing: '-1.5px', fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif'}}
                      >
                        How To Guides
                      </TitleStyled>
                      <UlStyled color={isDark ? "lightShade" : "darkShade"} style={{fontWeight: 300}}>
                        
                      <li>
                        <Link to={"https://www.startupmag.co.uk/choose-business-software/"} style={{fontSize: 14}}>
                            Choose business software
                          </Link>
                        </li>
                        <li>
                        <Link to={"https://www.startupmag.co.uk/find-an-investor/"} style={{fontSize: 14}}>
                            Find an investor
                          </Link>
                        </li>
                        <li>
                        <Link to={"https://www.startupmag.co.uk/find-an-office/"} style={{fontSize: 14}}>
                            Find an office
                          </Link>
                        </li>
                        <li>
                          <Link to={"https://www.startupmag.co.uk/build-a-website/"} style={{fontSize: 14}}>
                            Build a website
                          </Link>
                        </li>
                        <li>
                          <Link to={"https://www.startupmag.co.uk/get-more-clients/"} style={{fontSize: 14}}>
                            Get more clients
                          </Link>
                        </li>
                        <li>
                          <Link to={"https://www.startupmag.co.uk/how-to-recruit/"} style={{fontSize: 14}}>
                            How to recruit
                          </Link>
                        </li>
                        <li>
                          <Link to={"https://www.startupmag.co.uk/business-tactics/"} style={{fontSize: 14}}>
                            Business tactics
                          </Link>
                        </li>

                      
                      </UlStyled>
                    </div>
                  </Col>


                  <Col xs="6" lg="3">
                    <div className="mb-5 mb-lg-4">
                      <TitleStyled
                        variant="card"
                        color={'#ffffff'}
                        style={{fontSize: 18, fontWeight: 700, lineHeight: '28px', letterSpacing: '-1.5px', fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif'}}
                      >
                        Business Ideas
                      </TitleStyled>
                      <UlStyled color={isDark ? "lightShade" : "darkShade"} style={{fontWeight: 300}}>
                        <li>
                          <Link to={"https://www.startupmag.co.uk/dark-kitchen/"} style={{fontSize: 14}}>
                            Start a dark kitchen
                          </Link>
                        </li>
                        <li>
                        <Link to={"https://www.startupmag.co.uk/start-a-shipping-business/"} style={{fontSize: 14}}>
                            Shipping business
                          </Link>
                        </li>
                        <li>
                        <Link to={"https://www.startupmag.co.uk/paid-newsletter-business/"} style={{fontSize: 14}}>
                            Paid newsletters
                          </Link>
                        </li>
                        <li>
                        <Link to={"https://www.startupmag.co.uk/business-ideas/"} style={{fontSize: 14}}>
                            2021 UK business ideas
                          </Link>
                        </li>
                      
                      </UlStyled>
                    </div>
                  </Col>


                 
                  <Col xs="6" lg="3">
                    <div className="mb-5 mb-lg-4">
                      <TitleStyled
                        variant="card"
                        color={'#fff'}
                        style={{fontSize: 18, fontWeight: 700, lineHeight: '28px', letterSpacing: '-1.5px', fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif'}}
                      >
                        About Us
                      </TitleStyled>
                      <UlStyled color={isDark ? "lightShade" : "darkShade"} style={{fontWeight: 300}}>
                        <li>
                          <Link to={"https://www.startupmag.co.uk/contact/"} style={{fontSize: 14}}>
                            Contact Us
                          </Link>
                        </li>
                        <li>
                        <Link to={"https://www.startupmag.co.uk/privacy-policy/"} style={{fontSize: 14}}>
                            Privacy Policy
                          </Link>
                        </li>
                        <li>
                           <Link to={"https://www.startupmag.co.uk/terms-conditions/"} style={{fontSize: 14}}>
                            Terms & Conditions
                          </Link>
                        </li>
                        <li>
                           <Link to={"https://www.startupmag.co.uk/sitemap/"} style={{fontSize: 14}}>
                            Sitemap
                          </Link>
                        </li>
                      </UlStyled>
                    </div>
                  </Col>
               
               
                
                </Row>
              </Col>
            </Row>
          </Box>
          <CopyRightArea dark={isDark ? 1 : 0}>
            <Row className="align-items-center" id='footerMessage'>
         
              
              <Col sm="10">
              <Row>
          <p style={{lineHeight: 1.3, color: 'white', fontSize: 12}}>2021 Startupmag.co.uk &copy; Kohdao Ltd. 45B Ecclesbourne Road, London, N1 3AF, UK.<br></br>Registration 06261702. Data Protection No: ZA891746.</p>
          </Row>
   
              </Col>
              <Col sm="2" className="text-sm-right text-center" id='footerSocial'>
                <ul className="social-icons">
                  <li>
                    <a href="https://twitter.com/startupmagUK" target="_blank" rel="noreferrer">
                      <i className="icon icon-logo-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/startupmaguk/" target="_blank" rel="noreferrer">
                      <i className="icon icon-logo-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.facebook.com/startupmagUK/" target="_blank" rel="noreferrer">
                      <i className="icon icon-logo-facebook"></i>
                    </a>
                  </li>
                </ul>
              </Col>
            </Row>
          </CopyRightArea>
        
        </Container>
      </Box>
    </>
  );
};

export default Footer;
